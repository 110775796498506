const Utils = {
    
    base64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i=0; i<slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    },
   
    clone (data) {
        if (data.clone && typeof (data.clone) === "function") {
            return data.clone();
        } else {
            return (JSON.parse(JSON.stringify(data)));
        }
    },
    equals(data1, data2) {
        return JSON.stringify(data1) === JSON.stringify(data2);
    },
    noop (){
        // no operation : just do nothing
    },
    flatten(arr) {
        return arr.reduce(function(a, b){

            return a.concat(b);
        });
    },
    orPathSelector(obj, path) {
        const selector = path.split('|');
        let glo = false;
        while (selector && selector.length > 0) {
            const elt = selector.shift();
            const ret = Utils.objectPathSelector(obj, elt);
            if (ret !== undefined) {
                glo = glo || ret;
            }
        }
        return glo;
    },
    objectPathSelector(obj, path) {
        const selector = path.split('/');
        let o = obj;
        while (o && selector.length > 0) {
            let initialSelectorLength = selector.length;
            let elt = selector.shift();
            if (elt.indexOf('|') > -1) {
                return Utils.orPathSelector(o, elt);
            } else if (elt === '*') {
                if (initialSelectorLength > 1) {
                    let res;
                    for (let e in o) {
                        if (o.hasOwnProperty(e) && (res = Utils.objectPathSelector(o[e], selector.join('/'))))
                            break;
                    }
                    return res;
                }
                else if (initialSelectorLength == 1) {
                    for (let e in o) {
                        if (o.hasOwnProperty(e) && (o[e] === true || Utils.objectPathSelector(o[e], elt)))
                            return true;
                    }
                }
            } else
            {
                if (o.hasOwnProperty(elt)) {
                    o = o[elt];
                } else {
                    return undefined;
                }
            }
        }
        if (o === obj) {
            return undefined;
        } else {
            return o;
        }
    },
   createFormData(object, form, namespace) {
       const formData = form || new FormData();
       for (let property in object) {
           if (!object.hasOwnProperty(property) || !object[property]) {
               continue;
           }
           const formKey = namespace ? `${namespace}[${property}]` : property;
           if (object[property] instanceof Date) {
               formData.append(formKey, object[property].toISOString());
           } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
               this.createFormData(object[property], formData, formKey);
           } else {
               formData.append(formKey, object[property]);
           }
       }
       return formData;
    }
};

export default Utils;
