import React    from 'react';
import ReactDOM from 'react-dom';
import cx       from 'classnames';

class Icon extends React.Component {
    static propTypes = {
    }

    static defaultProps = {
    };

    state = {
    }

    constructor(props) {
        super(props);
    }
    render() {
        const {className, fa, fw,muted, primary, success, info, warning, danger, spin, name, ...others} = this.props;
        return (
            <i className={cx(className, (fa?'fa fa-':'glyphicon glyphicon-')+name,fw ? 'fa-fw': '', spin ? 'fa-circle-o-notch fa-spin' : '', {'text-muted':muted, 'text-primary':primary, 'text-success':success, 'text-info':info, 'text-warning':warning, 'text-danger':danger})} {...others} />
        );
    }
}

export default Icon;
