import Utils from './../services/Utils';
import Icon  from './../misc/Icon.js';
import React    from 'react';

var messageListener;

/**
 *
 * Modal Popup Manager
 *
 * @type {{addMessageListener: (function(*)), show: (function(*=)), confirm: (function(*=, *=, *=)), ok: (function(*, *=)), error: (function(*, *=))}}
 */
const ModalService = {
    addMessageListener( listener) {
        messageListener = listener;
    },
    show (msg) {
        setTimeout( ()=>{
            if(messageListener){
                messageListener(msg);

            }
        }, 10);
    },
    splitMessage(message) {
        message = message || '';
        message = Utils.flatten(message.replace("\r\n", '<br />').split('<br />').map( (m, i) => [<span key={i}>{m}</span>, <br key={'br'+i} />]));
        return message;
    },
    confirm (message, okHandler, cancelHandler, html, title, koHandler, isConfirmDisabled) {
        let disableConfirm = (typeof isConfirmDisabled === "function") ? isConfirmDisabled() : isConfirmDisabled;

        message = message || '';
        if(!html){
            message = this.splitMessage(message);
        }
        if(!title){
            title = 'Confirmer?';
        }
      ModalService.show({ confirm: true, okHandler: okHandler, koHandler: koHandler, cancelHandler: cancelHandler, bgHeader:'modal-warning ', title:[<Icon key="icon" fa name="question-circle" style={{marginRight:"15px"}}/>,title], body:<h4><span>{message}</span></h4>, isConfirmDisabled: disableConfirm});
    },
    ok(title, message) {
        message = message || '';
        message = this.splitMessage(message);
      ModalService.show({ bgHeader:'modal-success', title:[<Icon key="icon" name="ok" success style={{marginRight:"15px"}}/>,title], body:<h4><span>{message}</span></h4>});
    },
    error(title, message) {
        if(typeof message !== "undefined" && typeof message !== "string") {
            message = message.toString();
        }
        message = message || `Une erreur inconnue s'est produite`;
        message = this.splitMessage(message);
      ModalService.show({ bgHeader:'modal-danger', title:[<Icon key="icon" name="remove" danger style={{marginRight:"15px"}}/>, title], body:<h4><span>{message}</span></h4>});
    }
};

export default ModalService;
