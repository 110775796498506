//import { $, jQuery } from 'jquery';
// export for others scripts to use

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import ModalService from './ModalService';
import Style from './ModalMessage.scss';
//import 'jquery/jquery';
//import 'bootstrap';
//window.jQuery = window.$ = $;

//var $ = require("jquery");

/**
 *
 * Generic Modal Popup displayed on top.
 *
 */
class ModalMessage extends React.Component {

  //window.$ = $;
  //window.jQuery = jQuery;

  static propTypes = {
  }

  static defaultProps = {
  }

  initialState = {
    confirm: false,
    okHandler: () => {
    },
    koHandler: () => {
    },
    cancelHandler: () => {
    },
    title: '',
    body: '',
    modal: false,
    style: undefined,
    bgHeader: '',
    isConfirmDisabled: false
  }

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    ModalService.addMessageListener(msg => {
      this.setState(this.initialState);
      this.toggle();
      this.setState( msg );
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  _onCloseBtnClick = () => {
    if (this.state.confirm) {
      setTimeout(() => {
        this.state.cancelHandler();
      }, 50);
    }
    this.toggle();
  }

  _onYesBtnClick = () => {
    if (this.state.confirm) {
      setTimeout(() => {
        this.state.okHandler();
      }, 50);
    }
    this.toggle();
  }

  _onNoBtnClick = () => {    
    if (this.state.confirm) {
      setTimeout(() => {
        this.state.koHandler();
      }, 50);
    }
    this.toggle();
  }

  render() {
    let confirm;
    let deny;
    if (this.state.confirm) {
      confirm = <Button color="primary" onClick={this._onYesBtnClick} disabled={this.state.isConfirmDisabled}>Oui</Button>;
      if (this.state.koHandler)
        deny = <Button color="danger" onClick={this._onNoBtnClick}>Non</Button>;
    } else {
      confirm = undefined;
    }
    let modalStyle;
    if (this.state.style) {
      modalStyle = this.state.style;
    } else {
      modalStyle = {};
    }
    return (

      <div className="animated fadeIn">
        <Modal id="ModalMessage" isOpen={this.state.modal} toggle={this.toggle} className={this.state.bgHeader + ' ' + this.props.className} style={modalStyle}>
          <ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
          <ModalBody>
            {this.state.body}
          </ModalBody>
          <ModalFooter>
            {confirm}
            {deny}
            <Button color="secondary" onClick={this._onCloseBtnClick}>Fermer</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalMessage;
