import UtilisateurApi from './UtilisateurApi';

const UtilisateurService = {

  login(data) {
    return UtilisateurApi.login(data).then(response => {
      if (response.status === "KO") {
        throw response.message;
      } else {
        return response;
      }
    });
  },
  getUserByEmail(token) {
    return UtilisateurApi.getUserByEmail(token).then(response => {
      if (response.status === "KO") {
        throw response.message;
      } else {
        return response;
      }
    });
  },
  getUserSettings(userId) {
    return UtilisateurApi.getUserSettings(userId).then(response => {
      if (response.status === "KO") {
        throw response.message;
      } else {
        return response;
      }
    });
  },
  saveUserSettings(data) {
    return UtilisateurApi.saveUserSettings(data).then(response => {
      if (response.status === "KO") {
        throw response.message;
      } else {
        return response;
      }
    });
  },  
  getAllUsers() {
    return UtilisateurApi.getAllUsers().then(response => {
      if (response.status === "KO") {
        throw response.message;
      } else {
        return response;
      }
    });
  },
  setLastConnection(id) {
    return UtilisateurApi.setLastConnection(id).then(response => {
      if (response.status === "KO") {
        throw response.message;
      } else {
        return response;
      }
    });
  },
};

export default UtilisateurService;
