import React, { Component } from 'react';
import { Redirect, HashRouter, Route, Switch, withRouter } from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import './App.scss';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham-dark.css';
import 'react-slide-out/lib/index.css';
import ModalMessage from './modal-message/ModalMessage.js';

import UtilisateurService from './services/UtilisateurService';



const loading = () => <div className="animated fadeIn pt-3 text-center">Chargement...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Utilisateurs/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page403 = Loadable({
  loader: () => import('./views/Pages/Page403'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});


const PrivateRoute = ({ component: Component, cookies: cookies, ...rest }) => (
  <Route {...rest} render={(props) => (
    cookies.get('genialogie.user')
      ? <Component {...props}  />
      : <Redirect to={{
        pathname: '/Login',
        state: {
          from: props.location
        }
      }} />
  )} />
)

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale });
  }
}

class App extends Component {


  constructor(props) {
    super(props);

  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };


  render() {
    const { cookies } = this.props;

    return (
      <div>
        <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
          <HashRouter>
            <Switch>
              <Route exact path="/Login" name="Connexion" component={Login} />
              <Route exact path="/403" name="Page 403" component={Page403} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <PrivateRoute path="/" name="Home" component={DefaultLayout} cookies={cookies} />
            </Switch>
          </HashRouter>
          <ModalMessage />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withCookies(App);
