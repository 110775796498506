import request from 'superagent';
import nocache from 'superagent-no-cache';
import AppConstantes from '../constantes/AppConstantes.js';
import ApiUtils from './ApiUtils';

const UtilisateurApi = {
  login(data) {
    return ApiUtils.promisify(request.post(AppConstantes.metadata.url.login).send(data).use(nocache));
  },  
  getUserByEmail(token) {
    return ApiUtils.promisify(request.get(AppConstantes.metadata.url.getUserByEmail.replace('{token}', token)).use(nocache));
  },
  getUserSettings(userId) {
    return ApiUtils.promisify(request.get(AppConstantes.metadata.url.getUserSettings.replace('{userId}', userId)).use(nocache));
  },
  saveUserSettings(data) {
    return ApiUtils.promisify(request.post(AppConstantes.metadata.url.saveUserSettings).send(data).use(nocache));
  }, 
  getAllUsers() {
    return ApiUtils.promisify(request.get(AppConstantes.metadata.url.getAllUsers).use(nocache));
  },
  setLastConnection(id) {
    return ApiUtils.promisify(request.post(AppConstantes.metadata.url.setLastConnection.replace('{userId}',id)).use(nocache));
  }
};

export default UtilisateurApi;
