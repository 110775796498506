/**
 *
 * ApiUtils : remote api utils
 *
 * @type {{promisify: (function(*))}}
 */
const ApiUtils = {

    /**
     *
     * This function convert a callback superagent request into a Promise
     *
     * @param request
     * @returns {Promise}
     */
    promisify (request) {
        return new Promise( (resolve, reject) => {
            request.end((err, res) => {
                if (err) {
                    reject(err);
                } else {
                    if (res && res.status === 200) {
                        resolve(res.body);
                    } else {
                        reject(res.body);
                    }
                }
            });
        });
    }
};

export default ApiUtils;
