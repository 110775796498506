/**
 *
 * Application configuration here.
 *
 * All Remote URL should be defined here.
 * 
 */
const appConstantes = {
  metadata: {
    profile: {
      ADMIN: 1,
      VISITOR: 2
    },
    url: {
      villeSearch: "api/Metadata/GetVillesByAmorce?amorce={amorce}",
      getVilleById: "api/Metadata/GetVilleById?id={id}",
      departementSearch: "api/Metadata/GetDepartementsByAmorce?amorce={amorce}",
      getDepartementById: "api/Metadata/GetDepartementById?id={id}",
      getAllDepartements: "api/Metadata/GetAllDepartements",
      regionSearch: "api/Metadata/GetRegionsByAmorce?amorce={amorce}",
      getRegionById: "api/Metadata/GetRegionById?id={id}",
      getAllRegions: "api/Metadata/GetAllRegions",
      paysSearch: "api/Metadata/GetPaysByAmorce?amorce={amorce}",
      getPaysById: "api/Metadata/GetPaysById?id={id}",
      getAllPays: "api/Metadata/GetAllPays",
      getMetierById: "api/Metadata/GetMetierById?id={id}",
      getAllMetiers: "api/Metadata/GetAllMetiers",
      createMetier: "api/Metadata/CreateMetier",
      crupdateMetiers: "api/Metadata/crupdateMetiers",
      getReligionById: "api/Metadata/GetReligionById?id={id}",
      getAllReligions: "api/Metadata/GetAllReligions",
      createReligion: "api/Metadata/CreateReligion",
      getAuditByPersonneId: "api/Metadata/getAuditByPersonneId?id={id}",
      createPersonne: "api/Personne/Create?userId={userId}",
      deletePersonne: "api/Personne/DeletePersonne/{id}?userId={userId}",
      getPersonneById: "api/Personne/GetPersonneById?id={id}&userId={userId}",
      getLiensByPersonneId: "api/Personne/GetLinksByPersonneId?id={id}&userId={userId}",
      getPersonnes: "api/Personne/GetAllPersonnes?userId={userId}&filters={filters}",
      getDoublon: "api/Personne/GetDoublon",
      fillCircle: "api/Personne/FillCircle",
      getSosaComputing: "api/Personne/GetSosaComputing?userId={userId}",
      personneSearch: "api/Personne/GetPersonnesByAmorce?amorce={amorce}",
      login: "api/User/Login",
      getUserByEmail: "api/User/GetUserByEmail?token={token}",
      getUserSettings: "api/User/GetUserSettings?userId={userId}",
      saveUserSettings: "api/User/SaveUserSettings",
      setLastConnection: "api/User/SetLastConnection?userId={userId}",
      getAdressesByPersonneId: "api/Adresse/GetAdressesByPersonneId?id={id}",
      getAllTaches: "api/Metadata/GetAllTaches",
      getTachesByPersonneId: "api/Metadata/getTachesByPersonneId?id={id}",
      getTachesUnread: "api/Metadata/GetTachesUnread?id={id}",
      crupdateTachesByPersonne: "api/Metadata/crupdateTachesByPersonne",
      crupdateTaches: "api/Metadata/CrupdateTaches",
      getAllUsers: "api/User/GetAllUsers",
      setTacheRead: "api/Metadata/SetTacheRead",
      getSharedAdressesByAdresseId: "api/Adresse/GetSharedAdressesByAdresseId?{addressId}&personneId={personneId}",
      getAllConcernedPersonnes: "api/Personne/getAllConcernedPersonnes?{personneId}",
      GetReferentielGeographique: "api/Metadata/GetReferentielGeographique?type={type}",
      crupdatePays: "api/Metadata/crupdatePays?userId={userId}",
      crupdateRegion: "api/Metadata/crupdateRegion?userId={userId}",
      crupdateDepartement: "api/Metadata/crupdateDepartement?userId={userId}",
      crupdateVille: "api/Metadata/crupdateVille?userId={userId}",
      deletePays: "api/Metadata/deletePays/{id}?userId={userId}",
      deleteRegion: "api/Metadata/deleteRegion/{id}?userId={userId}",
      deleteDepartement: "api/Metadata/deleteDepartement/{id}?userId={userId}",
      deleteVille: "api/Metadata/deleteVille/{id}?userId={userId}",
    }
  }
};

export default appConstantes;
